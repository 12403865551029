body {
  background-color: #111111;
  background-attachment: fixed;
}

.menu-icon-desktop:hover {
  cursor: pointer;
}

.menu-icon-mobile:hover {
  cursor: pointer;
}

.carousel-indicators > button {
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  background-color: #800000 !important;
  border: none !important;
}

.carousel-indicators > button.active {
  width: 15px !important;
  height: 15px !important;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + .flip-card {
  z-index: 1;
}

input[type="checkbox"]:checked + .flip-card .flip-card-inner {
  transform: rotateZ(90deg) rotateX(180deg) scale(1.5);
}

input[type="checkbox"]:checked + .slider {
  transform: translate(90%, 0);
}

input[type="checkbox"]:checked + .slider .slide-out {
  transform: translate(-90%, 0);
  box-shadow: 10px 8px 10px #000000;
}

.slider {
  position: relative;
  transition: transform 1.3s;
  width: 30%;

}

.slide-out {
  transition: transform 1.3s;
  transform: scale(0.49, 0.99) translate(-50%, 0);
  position: absolute;
  width: 200%;
  height: 100%;
  top: 0;
  z-index: -1;
  background: linear-gradient(to bottom, #444444 0%, #222222 100%);
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
}

.slider ::-webkit-scrollbar {
  width: 0px;
}

.flip-card {
  background-color: transparent;
  width: 85%;
  height: 55vw;
  perspective: 1000px; 
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.3s;
  transform-style: preserve-3d;
  display: 'flex';
  justify-content: 'center';
  margin-bottom: 10px;
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-front {
  transform: rotateY(0deg);
}

/* Style the back side */
.flip-card-back {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  background: radial-gradient(circle at 100%, #444444 0%, #333333 85%, #222222 100%);
  box-shadow: -10px 8px 10px #000000;
  transform: rotateY(180deg);
  border-radius: 10px;
  position: relative;
  right: 10vw;
  text-align: center;
  padding: 5px;
}

.card-contents { 
  text-align: left;
  margin: 5px 20px 0 0;
  font-size: 3vw;
}

.card-contents p {
  margin: 0;
}

.card-contents ul {
  margin: 0;
  padding: 30px 0 0 5px;
}

h6 {
  margin: 0;
}

.button404 {
  border-radius: 5px;
  height: 40px;
  background-color: #800000;
  color: white;
}

.text404 {
  font-size: 40px;
}